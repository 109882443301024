import { createRouter, createWebHistory } from 'vue-router'
import { useStoreAuth } from '@/stores/storeAuth'
import { account, teams } from '@/api'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue')
    },
    {
      path: '/vstop',
      name: 'login',
      component: () => import('@/views/LoginView.vue')

    },
    {
      path: '/spremeni-geslo',
      name: 'spremeniGeslo',
      component: () => import('@/views/ChangePasswordView.vue')
    },
    {
      path: '/seznam/:page?',
      name: 'seznam',
      component: () => import('@/views/VpisListView.vue')
    },
    {
      path: '/vpis',
      name: 'vpis',
      component: () => import('@/views/VpisView.vue')
    },
    {
      path: '/uredi-vpis/:id',
      name: 'urediVpis',
      component: () => import('@/views/VpisView.vue')
    },
    {
      path: '/zbrisi-vpis/:id',
      name: 'deleteVpis',
      component: () => import('@/views/DeleteVpisView.vue')
    },
    {
      path: '/novo-obvestilo',
      name: 'newNote',
      component: () => import('@/views/NewNoteView.vue')
    },
    {
      path: '/uredi-obvestilo/:id',
      name: 'editNote',
      component: () => import('@/views/NewNoteView.vue')
    },
    {
      path: '/objave/:category?',
      name: 'posts',
      component: () => import('@/views/NotesView.vue')
    },
    {
      path: '/nov-kronicni',
      name: 'novKronicni',
      component: () => import('@/views/NewKronicniView.vue')
    },
    {
      path: '/uredi-kronicni/:id',
      name: 'editKronicni',
      component: () => import('@/views/NewKronicniView.vue')
    },
    {
      path: '/kronicni',
      name: 'kronicni',
      component: () => import('@/views/KronicniView.vue')
    },
    {
      path: '/kos',
      name: 'bin',
      component: () => import('@/views/BinView.vue')
    },
    {
      path: '/info/:type/:id/:trashId',
      name: 'documentInfo',
      component: () => import('@/views/DocumentInfoView.vue')
    },
    {
      path: '/izvoz',
      name: 'export',
      component: () => import('@/views/ExportView.vue')
    },
    {
      path: '/nov-svetovalec',
      name: 'newUser',
      component: () => import('@/views/NewUserView.vue')
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('@/views/TestAttachmentsView.vue')
    }
    
  ]
})

router.beforeEach( async ( to, from ) => {

  const storeAuth = useStoreAuth()

  try{
  
    const session = await account.getSession('current')

    const user = await account.get()

    const userteam = await teams.list()

    storeAuth.user.id     = session.userId
    storeAuth.user.email  = session.providerUid
    storeAuth.user.name   = user.name
    storeAuth.user.teams = userteam.teams

    if( storeAuth.allowedRoutes.includes( to.name )) {
      return { name: 'vpis' }
    }
  
  } catch {
  
    if( ! storeAuth.allowedRoutes.includes( to.name ) ) {
      return { name: 'home' }
    }

  }

})

export default router
