import { Client, Account, Teams, Databases, ID, Functions, Storage  } from 'appwrite'

const client = new Client()
const account = new Account(client)
const teams = new Teams(client)
const db = new Databases(client)
const storage = new Storage(client)
const functions = new Functions(client)


const schema = {
    dbid: '6467f169ef1ec2a8c463',
    objave: '6467f1cb8877eb55d7fc',
    kronicni: '6470aa4374115377e4cb',
    zapisi: '6470a48c4f5c4add9868',
    bin: '6498afccbf8e4424d8e2'
}

const bucket = {
    Tom: '64a253a1beeed25490a3'
}

const func = {
    basicStats: '64ae4ef51ccccf44fb08',
    listTeams: '64db88cfcc0b9c678baa',
    createMember: '64db96b27c4c1c8113ed'
}



client
    .setEndpoint(import.meta.env.VITE_AW_ENDPOINT)
    .setProject(import.meta.env.VITE_AW_PROJECT)
    .setLocale('sl')

export {
    client,
    account,
    teams,
    db,
    schema,
    bucket,
    ID,
    storage,
    functions,
    func
}
    

