import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import './assets/main.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCalendarDays, faEye, faPenToSquare, faUser, faTrash, faUpload, faDownload } from '@fortawesome/free-solid-svg-icons'
import JsonCSV from 'vue-json-csv'

library.add(faEye, faPenToSquare, faCalendarDays, faUser, faTrash, faUpload, faDownload )


const app = createApp(App)

const pinia = createPinia()

Sentry.init({
    app,
    dsn: "https://9b05ef583cd2d203730278d021d7aa68@o4505925304713216.ingest.sentry.io/4505941808775168",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost",/^https:\/\/tom\.gre\.si/,/^https:\/\/vpisnik\.e\-tom\.si/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

pinia.use(({store}) => {
    store.router = markRaw(router)
})

app.component('icon', FontAwesomeIcon)
app.component('download-csv', JsonCSV)

app.use(pinia)
app.use(router)

app.mount('#app')
