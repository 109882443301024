<template>
  <Navbar v-if="storeAuth.isUser" />
  <div class="container is-max-desktop">
    <RouterView />
  </div>
  <Footer v-if="storeAuth.isUser"/>
</template>

<script setup>
  import { computed } from 'vue';
  import { RouterView, useRoute } from 'vue-router'
  import { useStoreAuth } from '@/stores/storeAuth'
  import Navbar from '@/components/Navbar.vue'
  import Footer from '@/components/Footer.vue'
  

  const storeAuth = useStoreAuth()



</script>

