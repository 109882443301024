<template>
    <footer class="footer p-4">
        <div class="content has-text-centered">
            <p class="is-size-7">
                Tom Vpisnik, v. {{ version }} 
            </p>
        </div>
    </footer>
</template>

<script setup>

import { ref } from 'vue'

const version = ref(__APP_VERSION__)

</script>